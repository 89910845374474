<template>
  <div>
    <b-row>
      <b-col cols="12" md="5" xl="3" lg="4">
        <b-card no-body class="border-primary">
          <b-card-header
            class="d-flex flex-column align-items-start pt-75 pb-25"
          >
            <h5 class="mb-0">Situation du Projet</h5>
            <div>
              <b-badge
                class="text-uppercase"
                :variant="
                  projectStatus == 'profitable'
                    ? 'light-success'
                    : 'light-danger'
                "
              >
                {{ projectStatus }}
              </b-badge>
            </div>
          </b-card-header>

          <b-card-body>
            <ul class="list-unstyled my-1">
              <li>
                <span class="align-middle text-bold">Projet</span>
              </li>
              <li class="my-25">
                <small class="text-muted w-100">{{
                  budgetData.budget.project.code
                }}</small>
              </li>
              <li class="my-25">
                <b-button
                  :to="{
                    name: 'budget-trancking-add',
                    params: { id: budgetData.budget.project.uid },
                  }"
                  class="mt-2"
                  block
                  variant="info"
                >
                  Modifier
                </b-button>
              </li>
            </ul>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" xl="9" lg="8" md="7">
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col cols="12" class="d-flex justify-content-around flex-column">
              <!-- User Stats -->
              <b-row class="mb-1">
                <b-col class="d-flex align-items-center">
                  <b-avatar variant="light-primary" rounded>
                    <feather-icon icon="DollarSignIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ formatAmount(dutiesFree) }} XOF</h5>
                    <small>Montant HT</small>
                  </div>
                </b-col>
              </b-row>
              <!-- profit row start -->
              <b-row class="mb-1">
                <b-col class="d-flex align-items-center">
                  <b-avatar
                    :variant="hasProfit ? 'light-success' : 'light-danger'"
                    rounded
                  >
                    <feather-icon
                      v-if="hasProfit"
                      icon="TrendingUpIcon"
                      size="18"
                    />
                    <feather-icon v-else icon="TrendingDownIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ budgetData.budget.percent_expected_profit }} %
                    </h5>
                    <small>Profit Escompté %</small>
                  </div>
                </b-col>
                <b-col class="d-flex align-items-center">
                  <b-avatar
                    :variant="hasProfit ? 'light-success' : 'light-danger'"
                    rounded
                  >
                    <feather-icon
                      v-if="hasProfit"
                      icon="TrendingUpIcon"
                      size="18"
                    />
                    <feather-icon v-else icon="TrendingDownIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{
                        formatAmount(budgetData.budget.price_expected_profit)
                      }}
                      XOF
                    </h5>
                    <small>Profit Escompté XOF</small>
                  </div>
                </b-col>
              </b-row>
              <!-- profit row end -->

              <!-- rentability row start -->
              <b-row class="mb-1">
                <b-col class="d-flex align-items-center">
                  <b-avatar variant="light-danger" rounded>
                    <feather-icon icon="AlertCircleIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ roundDecimal(temporaryProfit) }} %</h5>
                    <small>Rentabilité Provisoire</small>
                  </div>
                </b-col>
                <b-col class="d-flex align-items-center">
                  <b-avatar variant="light-info" rounded>
                    <feather-icon icon="CheckIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ roundDecimal(realProfit) }} %</h5>
                    <small>Rentabilité Réel</small>
                  </div>
                </b-col>
              </b-row>
              <!-- rentability row end -->

              <!-- expenses row start -->
              <b-row>
                <b-col class="d-flex align-items-center">
                  <b-avatar variant="light-secondary" rounded>
                    <feather-icon icon="UserCheckIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ budgetData.re_sum }} XOF</h5>
                    <small>Dépenses Totales</small>
                  </div>
                </b-col>
                <b-col class="d-flex align-items-center">
                  <b-avatar variant="light-warning" rounded>
                    <feather-icon icon="UserPlusIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ budgetData.te_sum }} XOF</h5>
                    <small>Requête Total</small>
                  </div>
                </b-col>
              </b-row>
              <!-- expenses row end -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table
          ref="refListTable"
          striped
          hover
          responsive
          :fields="fields"
          :items="items"
          show-empty
          empty-text="Aucun enregistrements correspondants trouvés"
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(tax)="data">
            {{ data.item.tax != null ? data.item.tax.toUpperCase() : null }}
          </template>
          <template #cell(total)="data">
            {{ getTotal(data.item) }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BAvatar,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";
import { computed } from "@vue/composition-api";

export default {
  name: "StatsBudget",
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    budgetData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const items = computed(() => {
      if (props.budgetData?.budget.services)
        return props.budgetData?.budget.services;
      return [];
    });
    const fields = [
      {
        key: "id",
        label: "Id",
      },
      { key: "designation", label: "Services" },
      { key: "cost_rate", label: "Taux Coutant" },
      { key: "unit", label: "Unités" },
      { key: "qty", label: "Quantités" },
      { key: "profitability", label: "Rentabilités" },
      { key: "duty_free", label: "HT" },
      { key: "tax", label: "Taxe" },
      { key: "total", label: "Total" },
    ];


    const taxeOptions = [
      {
        label: "TVA",
        slug: "tva",
        value: 18,
      },
      {
        label: "AIB",
        slug: "aib",
        value: 3,
      },
    ];

    const dutiesFree = computed(() => {
      const services = props.budgetData?.budget.services;
      if (services == undefined) return 0;

      return services.reduce((prevServ, currServ) => {
        return prevServ + currServ.duty_free;
      }, 0);
    });

    const formatAmount = (amount) => Intl.NumberFormat("fr-FR").format(amount);

    const hasProfit = computed(() => {
      const profit = props.budgetData?.budget.price_expected_profit;
      if (parseFloat(profit) < 0) return false;
      return true;
    });

    const realProfit = computed(() => {
      const reSum = props.budgetData.re_sum; // real expense
      if (parseFloat(dutiesFree.value) > 0) {
        const numerator = parseFloat(dutiesFree.value) - parseFloat(reSum);
        const denominator = parseFloat(dutiesFree.value);
        return (numerator / denominator) * 100;
      }
      return 0;
    });

    const temporaryProfit = computed(() => {
      const reSum = props.budgetData.re_sum; // real expense
      const teSum = props.budgetData.te_sum; // temporary expense

      if (parseFloat(dutiesFree.value) > 0) {
        const numerator =
          parseFloat(dutiesFree.value) -
          (parseFloat(reSum) + parseFloat(teSum));
        const denominator = parseFloat(dutiesFree.value);

        return (numerator / denominator) * 100;
      }
      return 0;
    });

    const roundDecimal = (nombre, precision) => {
      let currPrecision = precision || 2;
      let tmp = Math.pow(10, currPrecision);
      return Math.round(nombre * tmp) / tmp;
    };

    const projectStatus = computed(() => {
      let status = "profitable";
      const reSum = props.budgetData.re_sum;
      const profit = props.budgetData?.budget.price_expected_profit;
      if ((parseFloat(dutiesFree.value) - parseFloat(reSum)) < parseFloat(profit)) {
        status = "non-rentable";
      }
      return status;
    });


    const checkNumber = (num, rtnVal) => {
      if (!rtnVal) rtnVal = 0;
      if (num !== null && num !== "" && num !== undefined) return num;
      return rtnVal;
    };

    const findTax = (itemTax) =>
      taxeOptions.find((t) => t.slug === itemTax);

    /**
     * Get service's total based on duty free and tax
     * 
     * @param {object} service The current service
     * @return {number} sum of duty free with tax to get tax included
     */
    const getTotal = (service) => {
      const df = checkNumber(service.duty_free); // Get current service duty free
      const t = findTax(service.tax); // Get current service taxe
      let taxCalc = 0;
      if (t != undefined)
        taxCalc = (parseFloat(df) * parseFloat(checkNumber(t?.value, 1))) / 100; // calculate taxe to add on current service duty free

      return parseFloat(df) + taxCalc;
    }

    return {
      items,
      fields,
      dutiesFree,
      hasProfit,
      temporaryProfit,
      realProfit,
      projectStatus,

      formatAmount,
      roundDecimal,
      getTotal,
    };
  },
};
</script>

<style></style>
