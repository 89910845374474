<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="budgetData === undefined">
      <h4 class="alert-heading">Erreur lors de la récupération du suivi</h4>
      <div class="alert-body">
        Pas de suivi trouvé
        <!-- <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users. -->
      </div>
    </b-alert>

    <template v-if="budgetData">
      <stats-budget :budget-data="budgetData" />
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import router from "@/router";
import { ref, onMounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import StatsBudget from "./StatsBudget.vue";

import axios from "@axios";
import { useRouter } from "@core/utils/utils";

const requestOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

export default {
  name: "RealTracking",
  props: ["id"],
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    StatsBudget,
  },
  setup(props) {
    const budgetData = ref(null);
    const fetchProjectBudget = () => {
      axios
        .get(`finances/real-tracking/${props.id}`, requestOptions)
        .then(({ data }) => {
          budgetData.value = data.resource;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            budgetData.value = undefined;
          }
        });
    };

    onMounted(() => {
      fetchProjectBudget();
    });

    return {
      budgetData,
    };
  },
};
</script>

<style></style>
